import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/index'
import Layout from '@/layout'

const routes = [
  {
    path:"/:catchAll(.*)",
    redirect:'/', 
    meta: { title: '' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: { title: '登录页' },
  },
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页', auth: false },
    component: Layout,
  },
  {
    path: '/bi/robot',
    name: 'BiRobot',
    meta: { title: '机器人方阵', auth: false, hide: true },
    component: () => import('@/views/bi/robot'),
  },
  {
    path: '/test',
    meta: { title: '测试', auth: false, hide: true },
    component: Layout,
    children: [
      {
        path: 'test',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/test'),
        name: '测试',
        meta: {
          title: '测试',
        }
      }
    ]
  },
  // {
  //   path: '/index',
  //   component: Layout,
  //   redirect: 'noRedirect',
  //   meta: { title: '首页', icon: 'icon-ota-zhu-ye', affix: true, auth: false},
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard'),
  //       name: '首页',
  //       meta: { 
  //         title: '首页', 
  //         icon: 'icon-ota-zhu-ye', 
  //         affix: true,
  //         auth: false
  //       }
  //     }
  //   ]
  // },
  {
    path: '/equipment',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '资产管理',
      icon: 'icon-ota-jiqiren',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'sale',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/sale'),
        name: '销售管理',
        meta: {
          title: '销售管理', 
          auth: true
        }
      },
      {
        path: 'robotManagement',
        component: () => import(/* webpackChunkName: "equipment" */'@/views/equipment/robot'),
        name: '机器人本体',
        meta: {
          title: '机器人本体',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'cabinetManagement',
        component: () => import(/* webpackChunkName: "equipment" */'@/views/equipment/cabinet'),
        name: '控制柜',
        meta: {
          title: '控制柜',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'wxacode',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/wxacode'),
        name: '机器人小程序码',
        meta: {
          title: '机器人小程序码',
          auth: false
        }
      },
    ]
  },
  {
    path: '/status',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '状态监控',
      icon: 'icon-ota-shebeizhuangtai',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'robotStatus',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/status/robot'),
        name: '机器人本体状态',
        meta: {
          title: '机器人本体',
          auth: true,
          nocache: true
        }
      },
      // {
      //   path: 'cabinetStatus',
      //   component: () => import('@/views/status/cabinet'),
      //   name: '控制柜状态',
      //   meta: {
      //     title: '控制柜',
      //     nocache: true
      //   }
      // },
      {
        path: 'joint',
        component : () => import(/* webpackChunkName: "equipment" */ '@/views/status/joint'),
        name: '机器人关节状态',
        meta: {
          title: '机器人关节',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'jointChart',
        component : () => import(/* webpackChunkName: "equipment" */ '@/views/status/jointChart'),
        name: '关节状态图',
        meta: {
          title: '关节状态图',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'largeScreenM',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/status/largeScreenM'),
        name: '大屏管理',
        meta: {
          title: '大屏管理',
          auth: true,
          nocache: true
        }
      },
    ]
  },
  {
    path: '/event',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '事件监控',
      icon: 'icon-ota-yunyingzhongxin',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'powerOnEvent',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/event/powerOn'),
        name: '上电记录',
        meta: {
          title: '上电记录',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'enableEvent',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/event/enable'),
        name: '上使能记录',
        meta: {
          title: '上使能记录',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'crashEvent',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/event/crash'),
        name: '碰撞记录',
        meta: {
          title: '碰撞记录',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'emergencyEvent',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/event/emergency'),
        name: '急停记录',
        meta: {
          title: '急停记录',
          auth: true,
          nocache: true
        }
      }
    ]
  },
  {
    path: '/error',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '故障排查',
      icon: 'icon-ota-gaojing',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'alarm',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/error/alarm'),
        name: '报警信息',
        meta: {
          title: '报警信息',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'error',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/error/error'),
        name: '报错信息',
        meta: {
          title: '报错信息',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'errorLog',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/error/errorLog'),
        name: '故障日志',
        meta: {
          title: '故障日志',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'errorLogNew',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/error/errorLogNew'),
        name: '故障日志(新)',
        meta: {
          title: '故障日志(新)',
          auth: true,
          nocache: true
        }
      },
    ]
  },
  {
    path: '/collect',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '预测性维护',
      icon: 'icon-ota-gongyezujian-yibiaopan',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'healthScoreList',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/collect/healthScoreList'),
        name: '健康评估',
        meta: {
          title: '健康评估',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'healthScoreTrend',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/collect/healthScoreTrend'),
        name: '健康趋势',
        meta: {
          title: '健康趋势',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'taskList',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/collect/taskList'),
        name: '任务管理',
        meta: {
          title: '任务管理',
          auth: true,
          nocache: true
        }
      },
      {
        path: 'messageList',
        component: () => import(/* webpackChunkName: "equipment" */ '@/views/collect/messageList'),
        name: '报文管理',
        meta: {
          title: '报文管理',
          auth: true,
          nocache: true
        }
      },
    ]
  },
  {
    path: '/version',
    component: Layout,
    redirect: 'noRedirect',
    meta: { 
      title: '版本管理', 
      icon: 'icon-ota-icon_yingyongguanli', 
      auth: true,
      menu: ['side'],
      affix: true 
    },
    children: [
      {
        path: 'mapping',
        component: () => import(/* webpackChunkName: "version" */ '@/views/service/version'),
        name: '版本关联',
        meta: {
          title: '版本关联',
          auth: true,
        }
      },
      {
        path: 'software',
        component: () => import(/* webpackChunkName: "version" */ '@/views/service/software'),
        name: '软件管理',
        meta: {
          title: '软件管理',
          auth: true,
        }
      },
      {
        path: 'upgrade',
        component: () => import(/* webpackChunkName: "version" */ '@/views/service/upgrade'),
        name: '远程升级',
        meta: {
          title: '远程升级',
          auth: false,
        }
      },
    ]
  },
  {
    path: '/service',
    component: Layout,
    redirect: 'noRedirect',
    meta: { 
      title: '技术服务', 
      icon: 'icon-ota-jishufuwu1', 
      auth: true,
      menu: ['side'],
      affix: true 
    },
    children: [
      {
        path: 'workOrder',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/workOrder'),
        name: '工单管理',
        meta: {
          title: '工单管理',
          auth: true
        }
      },
      {
        path: 'ota',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/ota'),
        name: '接入OTA',
        meta: {
          title: '接入OTA',
          auth: true
        }
      },
      {
        path: 'feedback',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/feedback'),
        name: '问题反馈',
        meta: {
          title: '问题反馈',
          auth: true
        }
      },
      {
        path: 'forum',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/forum'),
        name: '技术论坛',
        meta: {
          title: '技术论坛',
          auth: true
        }
      },
      {
        path: 'virtual',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/virtual'),
        name: '虚拟体验',
        meta: {
          title: '虚拟体验',
          auth: true
        }
      },
      {
        path: 'clue',
        component: () => import(/* webpackChunkName: "service" */ '@/views/service/clue'),
        name: '用户线索',
        meta: {
          title: '用户线索',
          auth: true
        }
      },
    ]
  },
  {
    path: '/activity',
    component: Layout,
    meta: {
      title: '活动管理',
      icon: 'icon-ota-yunyingguanli',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/list'),
        name: '活动列表',
        meta: {
          title: '活动列表',
          auth: true
        }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/detail'),
        name: '活动详情',
        meta: {
          title: '活动详情',
          auth: true,
          hide: true,
        }
      },
      {
        path: 'review',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/review'),
        name: '展后详情',
        meta: {
          title: '展后详情',
          auth: true,
          hide: true,
        }
      },
      {
        path: 'subActivity',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/subActivity'),
        name: '子活动',
        meta: {
          title: '子活动',
          auth: false,
          hide: true,
        }
      },
      {
        path: 'wxacode',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/wxacode'),
        name: '签到码',
        meta: {
          title: '签到码',
          auth: true,
        }
      },
      {
        path: 'signUp',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/signUp'),
        name: '活动报名',
        meta: {
          title: '活动报名',
          auth: true,
        }
      },
      {
        path: 'signIn',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/signIn'),
        name: '活动签到',
        meta: {
          title: '活动签到',
          auth: true,
        }
      },
      {
        path: 'points',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/points'),
        name: '积分奖励',
        meta: {
          title: '积分奖励',
          auth: true,
        }
      },
      {
        path: 'packetJoin',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/packetJoin'),
        name: '活动参与',
        meta: {
          title: '活动参与',
          auth: true,
        }
      },
      {
        path: 'packetReceive',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/packetReceive'),
        name: '红包领取',
        meta: {
          title: '红包领取',
          auth: true,
        }
      },
      {
        path: 'sale',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/sale'),
        name: '销售收集表',
        meta: {
          title: '销售收集表',
          auth: true,
        }
      },
    ]
  },
  {
    path: '/points',
    component: Layout,
    meta: {
      title: '积分管理',
      icon: 'icon-ota-chucun',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/detail'),
        name: '积分明细',
        meta: {
          title: '积分明细',
          auth: true,
        }
      },
      {
        path: 'record',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/record'),
        name: '积分详情',
        meta: {
          title: '积分详情',
          auth: true,
        }
      },
      {
        path: 'award',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/award'),
        name: '积分商品',
        meta: {
          title: '积分商品',
          auth: true,
        }
      },
      {
        path: 'category',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/category'),
        name: '商品类别',
        meta: {
          title: '商品类别',
          auth: true,
        }
      },
      {
        path: 'exchange',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/exchange'),
        name: '积分兑换',
        meta: {
          title: '积分兑换',
          auth: true,
        }
      },
      {
        path: 'task',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/task'),
        name: '积分任务',
        meta: {
          title: '积分任务',
          auth: true,
        }
      },
      {
        path: 'referrer',
        component: () => import(/* webpackChunkName: "other" */ '@/views/points/referrer'),
        name: '推荐统计',
        meta: {
          title: '推荐统计',
          auth: true,
        }
      },
    ]
  },
  {
    path: '/visitor',
    component: Layout,
    meta: {
      title: '访客管理',
      icon: 'icon-ota-quanxianshenpi',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'visitor',
        component: () => import(/* webpackChunkName: "other" */ '@/views/other/visitor'),
        name: '访客登记',
        meta: {
          title: '访客登记',
          auth: true,
        }
      }
    ]
  },
  {
    path: '/train',
    component: Layout,
    meta: {
      title: '培训管理',
      icon: 'icon-ota-zhihangfankui',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'train',
        component: () => import(/* webpackChunkName: "other" */ '@/views/other/train'),
        name: '培训管理',
        meta: {
          title: '培训管理',
          auth: true,
        }
      },
      {
        path: 'questionnaire',
        component: () => import(/* webpackChunkName: "other" */ '@/views/other/questionnaire'),
        name: '问卷结果',
        meta: {
          title: '问卷结果',
          auth: true,
        }
      }
    ]
  },
  {
    path: '/resource',
    component: Layout,
    meta: {
      title: '资源管理',
      icon: 'icon-ota-yunduanxiazai',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "other" */ '@/views/resource/list'),
        name: '资源管理',
        meta: {
          title: '资源管理',
          auth: true,
        }
      },
    ]
  },
  {
    path: '/banner',
    component: Layout,
    meta: {
      title: 'banner管理',
      icon: 'icon-ota-changjingguanli',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "other" */ '@/views/banner/list'),
        name: 'banner列表',
        meta: {
          title: 'banner列表',
          auth: true,
        }
      },
      {
        path: 'content',
        component: () => import(/* webpackChunkName: "other" */ '@/views/banner/content'),
        name: '内容列表',
        meta: {
          title: '内容列表',
          auth: true,
        }
      }
    ]
  },
  {
    path: '/system',
    component: Layout,
    redirect: 'noRedirect',
    meta: {
      title: '系统设置',
      icon: 'icon-ota-icon_shezhi',
      auth: true,
      menu: ['side'],
      affix: true
    },
    children: [
      {
        path: 'role',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/role'),
        name: '角色管理',
        meta: {
          title: '角色管理',
          auth: true,
        }
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/user'),
        name: '用户管理',
        meta: {
          title: '用户管理',
          auth: true,
        }
      },
      {
        path: 'authority',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/authority'),
        name: '机器人权限列表',
        meta: {
          title: '机器人权限列表',
          auth: true,
        }
      },
      {
        path: 'authApply',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/authApply'),
        name: '机器人权限审批',
        meta: {
          title: '机器人权限审批',
          auth: true,
        }
      }
    ]
  }
]

const menus = []
routes.map((route) => {
  // 需要授权
  if (route.meta.auth && !route.meta.hide) {
    let menu = {
      path: route.path,
      label: route.meta.title,
      children: []
    }
    route.children.forEach((sub) => {
      menu.children.push({
        path: menu.path+'/'+sub.path,
        label: sub.meta.title,
      })
    })
    menus.push(menu)
  }
})

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior: () => ({ y: 0 })
})

function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to) => {
  // admin
  // const userInfo = window.sessionStorage.getItem('userInfo') || '[]'
  // if (JSON.parse(userInfo).includes('admin')) return true
  // 权限校验
  if (to.meta && to.meta.auth) {
    let menus = store.state.menus
    for (let menu of menus) {
      if (menu.path === to.path) return true
      for (let sub of menu.children||[]) {
        if (sub.path === to.path) return true
      }
    }
    return { path: '/' }
  } else {
    return true
  }
})
export default router
export {
  routes,
  menus,
  resetRouter
}
