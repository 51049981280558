<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew">
      <template #handle>
        <div class="form-col">
          <!-- <el-form-item>
            <el-button type="danger" @click="batchDelete">删除</el-button>
          </el-form-item> -->
          <el-form-item>
            <el-button type="danger" @click="batchStop">中止</el-button>
            <el-button type="primary" plain @click="batchPublish">发布</el-button>
          </el-form-item>
        </div>
      </template>
    </Filter>
    <Table ref="tableId" :tableData="tableDataList" :total="total" :size="size" @update="updatePage" tooltip-effect="light" single @select="selectHandle">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="syncTime" label="同步时间" width="150" />
      <el-table-column prop="name" label="活动名称" width="150" />
      <el-table-column prop="area" label="活动区域" width="120" />
      <el-table-column prop="startDate" label="开始日期" width="120" />
      <el-table-column prop="endDate" label="结束日期" width="120" /> 
      <!-- <el-table-column label="活动日期" width="200" >
        <template #default="{row}">{{ row.startDate+' 至 '+row.endDate }}</template>
      </el-table-column>
      <el-table-column label="活动时间" width="200" >
        <template #default="{row}">{{ row.startTime+' 至 '+row.endTime }}</template>
      </el-table-column> -->
      <el-table-column prop="type" label="活动类型" width="120" >
        <template #default="{row}">{{ row.type }}</template>
      </el-table-column>
      <el-table-column prop="status" label="活动状态" width="120" />
      <el-table-column prop="publish" label="发布状态" width="120" >
        <template #default="{row}">{{ row.publish?'已发布':'未发布' }}</template>
      </el-table-column>
      <el-table-column label="二维码" width="120" >
        <template #default="{row}">
          <el-button type="text" size="small" @click="downloadWXAcode(row)">下载</el-button>
        </template>
      </el-table-column>
      <el-table-column label="签到/报名人数" width="120" >
        <template #default="{row}">{{row.signInCount}}/{{row.signUpCount}}</template>
      </el-table-column>
      <el-table-column prop="owner" label="负责人" width="120" />
      <el-table-column prop="isShow" label="前端展示" width="120" >
        <template #default="{row}">
          {{ row.isShow ? '全部可见' : '内部可见' }}
        </template>
      </el-table-column>
      <el-table-column prop="subActivities" label="子活动" width="120" >
        <template #default="{row}">
          <el-button type="text" size="small" @click="showSubActivities(row)">{{ row.subActivities?.length || 0 }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="activityEdit(row, '/activity/detail')">活动编辑</el-button>
          <el-button v-if="row.status =='已结束'" type="primary" plain size="mini" @click="activityEdit(row, '/activity/review')">展后编辑</el-button>
          <el-button v-if="row.status !='已结束'" type="primary" plain size="mini" @click="subActivityEdit(row.id)">新建子活动</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="新建活动" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="detailform.name" placeholder="活动名称" clearable />
        </el-form-item>
        <el-form-item label="活动类型" prop="type">
          <el-select v-model="detailform.type" placeholder="活动类型" clearable>
            <el-option
              v-for="item in newTypeList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动日期: " prop="dateRange">
          <el-date-picker
            v-model="detailform.dateRange"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          />
        </el-form-item>
        <el-form-item label="活动时间: " prop="timeRange">
          <el-time-picker
            v-model="detailform.timeRange"
            is-range
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-dialog :title="subActivity.parent.name" v-model="subActivity.visible" destroy-on-close width="75%" :modal="true" :append-to-body="true">
      <!-- <template #title>
        <el-button @click="subActivityEdit(subActivity.parentId)" type="primary" size="mini" plain>添加</el-button>
      </template> -->
      <!-- <el-descriptions title="">
        <el-descriptions-item label="主活动:">{{  }}</el-descriptions-item>
      </el-descriptions> -->
      <el-table class="dialog-table" height="500px" :data="subActivity.data" border stripe>
        <el-table-column prop="name" label="子活动名称" width="120" />
        <el-table-column prop="location" label="地点" />
        <el-table-column prop="startDate" label="日期" width="200" >
          <template #default="{row}">
            {{ row.startDate }} - {{ row.endDate }}
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="时间" width="120" >
          <template #default="{row}">
            {{ row.startTime }} - {{ row.endTime }}
          </template>
        </el-table-column>
        <el-table-column label="二维码" >
          <template #default="{row}">
            <el-button type="text" size="small" @click="downloadWXAcode(row)">下载</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="活动状态" width="150" /> -->
        <el-table-column prop="signUpCount" label="报名人数" width="120" />
        <el-table-column prop="createBy" label="创建人" width="120" />
        <el-table-column prop="createTime" label="创建时间" width="120" />
        <el-table-column label="操作" width="120px">
          <template #default="{ row }">
            <!-- <el-button @click="deleteApplyform(scope.$index)" type="danger" plain size="mini">删除</el-button> -->
            <el-button type="text" size="mini" @click="subActivityEdit(subActivity.parent.id, row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import useActivity from './useActivity';

import { common } from "@/common/mixin";
import { request, downloadFile } from "@/utils/request";
const activityArea = ["华东", "华南", "华北", "华中", "西南", "西北", "东北"]

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const { activityTypeList, activityStatusList } = useActivity()
    const newTypeList = ['新机上线','积分兑换', '数字化营销']
    const state = reactive({
      params: [
        {
          type: "Input",
          key: "name",
          value: "",
          label: "活动名称",
        },
        {
          type: "Select",
          key: "type",
          value: "",
          label: "活动类型",
          options: {
            data: activityTypeList,
            label: "name",
            value: "name"
          }
        },
        {
          type: "Input",
          key: "owner",
          value: "",
          label: "负责人",
        },
        {
          type: "Select",
          key: "status",
          value: "",
          label: "活动状态",
          options: {
            data: activityStatusList,
            label: "name",
            value: "value"
          }
        },
        {
          type: "Select",
          key: "area",
          value: "",
          label: "活动区域",
          options: {
            data: activityArea
          }
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "daterange",
            "format": "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }
        },

      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      batchDataList: [],
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        name: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        dateRange: [],
        timeRange: []
      },
    })
    const subActivity = reactive({
      visible: false,
      parent: {},
      data: []
    })
    const detailformRules = reactive({
      name: {
        required: true,
        message: '请输入活动名称',
        trigger: 'blur'
      },
      type: {
        required: true,
        message: '请选择活动类型',
        trigger: 'change'
      },
      dateRange: {
        required: true,
        message: '请选择培训日期',
        trigger: 'change',
      },
      timeRange: {
        required: true,
        message: '请选择培训时间',
        trigger: 'change',
      },
    })

    const getData = async () => {
      try {
        const res = await request("getActivityList", {
          ...state.query,
          page: state.page,
          size: state.size,
        })
        state.tableDataList = res.records
        state.total = res.total
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      console.log('searchData', data)
      state.query = data;
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startDate = data.dateRange[0]
        state.query.endDate = data.dateRange[1]
      } else {
        state.query.startDate = ''
        state.query.endDate = ''
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    // 表格select事件
    const selectHandle = (data) => {
      console.log(data)
      state.batchDataList = data.map((item)=>{
        return item.id
      })
    }
    const addNew = () => {
      state.dialogFormVisible = true
      Object.keys(state.detailform).forEach(key => {
        state.detailform[key] = null
      })
    }
    const onFormSubmit = async () => {
      try {
        state.detailformRef && await state.detailformRef.validate()
        state.detailform.startDate = state.detailform.dateRange[0]
        state.detailform.endDate = state.detailform.dateRange[1]
        state.detailform.startTime = state.detailform.timeRange[0]
        state.detailform.endTime = state.detailform.timeRange[1]
        await request('saveActivity', state.detailform)
        getData()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const batchDelete = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要删除的活动!"
          })
          return
        }
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    // 中止活动
    const batchStop = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要中止的活动!"
          })
          return
        }
        await request("stopActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    // 发布活动
    const batchPublish = async () => {
      try {
        if (state.batchDataList.length<1) {
          ElMessage({
            showClose: true,
            type: "error",
            message: "请选择要发布的活动!"
          })
          return
        }
        await request("publishActivity", {
          id: state.batchDataList[0]
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "操作成功!"
        })
        getData()
      } catch (error) {
        console.error(error)
      }
    }
    // 二维码下载
    const downloadWXAcode = async (row) => {
      try {
        const res = await downloadFile("activityAllWXacode", {
          activityId: row.id
        })
        let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = row.name + ' 二维码.zip'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    // 活动编辑
    const activityEdit = async (data, url) => {
      console.log(data)
      router.push(`${url}?id=${data.id}&type=${data.type}`)
    }
    // 子活动编辑
    const subActivityEdit = async (parentId='', id='') => {
      router.push(`/activity/subActivity?parentId=${parentId}&id=${id}`)
    }
    const showSubActivities = (row) => {
      subActivity.visible = true
      subActivity.data = row.subActivities
      subActivity.parent = row
    }
    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }
    
    const setupInit = () => {
      try {
        getData()
        // request("syncActivity")
      } catch (error) {
         console.error(error)
      }
    }
    setupInit()
    return {
      newTypeList,
      ...toRefs(state),
      subActivity,
      detailformRules,
      getData,
      searchData,
      addNew,
      onFormSubmit,
      updatePage,
      downloadWXAcode,
      disabledDate,
      batchStop,
      batchPublish,
      batchDelete,
      selectHandle,
      activityEdit,
      subActivityEdit,
      showSubActivities
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
