<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
      <template #default="{query}">
        <div class="form-col">
          <el-form-item label="来访事由: ">
            <el-select
              v-model="query.visitType"
              placeholder="来访事由"
            >
              <el-option
                v-for="(item) in visitTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="form-col">
          <el-form-item label="访问日期: ">
            <el-date-picker
              v-model="query.dateRange"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </div>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="visitTime" label="访问时间" width="180px" />
      <el-table-column prop="name" label="姓名" width="120px" />
      <el-table-column prop="phone" label="电话" width="120px" >
        <template #default="{row}">
          {{ row.phone &&  (String(row.phone).slice(0,3)+'****'+String(row.phone).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="email" label="邮箱" width="180px" />
      <el-table-column prop="startTime" label="开始时间" width="120px" />
      <el-table-column prop="endTime" label="结束时间" width="120px" />
      <el-table-column prop="empName" label="拜访人" width="120px" />
      <el-table-column prop="address" label="拜访地址" width="120px" />
      <el-table-column prop="visitType" label="来访事由"  width="120px" >
        <template #default="{row}">{{getVisitName(row.visitType)}}</template>
      </el-table-column>
      <el-table-column prop="other" label="备注" />
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      params: [
        {
          key: "empName",
          value: "",
          label: "被访问人姓名",
        }
      ],
      visitTypeList: [{
          id: 1,
          name: '商务沟通',
        },{
          id: 2,
          name: '研发沟通',
        },{
          id: 10,
          name: '政务访谈',
        },{
          id: 11,
          name: '参加活动',
        },{
          id: 13,
          name: '客户参观',
        },{
          id: 4,
          name: '其他',
        }]
    })
    const getData = async () => {
      try {
        const res = await request('getVisitorList', {
          ...state.query,
          page: state.page,
          size: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startDate = data.dateRange[0]
        state.query.endDate = data.dateRange[1]
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    }
    const getVisitName = (type='') => {
      for (let item of state.visitTypeList) {
        if (type === item.id) return item.name
      }
      return ''
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      getVisitName
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
</style>