<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="time" label="培训时间" />
      <el-table-column prop="location" label="培训地点" width="180px" />
      <el-table-column prop="subject" label="培训主题" width="180px" />
      <el-table-column prop="lecturers" label="培训讲师">
        <template #default="{row}">{{row.lecturers.map((item)=>{return item.name}).join(', ')}}</template>
      </el-table-column>
      <el-table-column prop="qrCode" label="二维码" width="120px" >
        <template #default="{row}">
          <el-button type="text" size="small" @click="downloadWXAcode(row)">下载</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180px" />
      <el-table-column prop="count" label="参与答卷人数"  width="120px" >
        <template #default="{row}">
          <el-button type="text" size="small" @click="checkResult(row)">{{row.count || '无'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="150px" fixed="right">
        <template #default="{row}">
          <el-button type="primary" plain size="mini" @click="editDetail(row)">编辑</el-button>
          <el-button type="danger"  plain size="mini" @click="deleteDetail(row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="培训" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="100px">
        <el-form-item label="培训时间: " prop="dateRange">
          <el-date-picker
            v-model="detailform.dateRange"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="培训地点" prop="location">
          <el-input v-model="detailform.location" placeholder="培训地点" />
        </el-form-item>
        <el-form-item label="培训主题" prop="subject">
          <el-input v-model="detailform.subject" placeholder="培训主题" />
        </el-form-item>
        <el-form-item label="培训讲师" prop="lecturers">
          <el-select v-model="detailform.lecturers" placeholder="培训讲师" :remote-method="staffQuery" multiple filterable remote clearable>
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onFormSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { request } from "@/utils/request";
import { common } from "@/common/mixin";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      params: [
        {
          type: "Input",
          key: "subject",
          value: "",
          label: "培训主题"
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "daterange",
            "format": "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "range-separator": "至",
            "start-placeholder": "开始时间",
            "end-placeholder": "结束时间"
          }
        },
      ],
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        startTime: '',
        endTime: '',
        dateRange: [],
        location: '',
        subject: '',
        lecturers: []
      },
      staffList: []
    })
    const detailformRules = reactive({
      dateRange: {
        required: true,
        message: '请选择培训时间',
        trigger: 'change',
      },
      location: {
        required: true,
        message: '请输入培训地点',
        trigger: 'blur'
      },
      subject: {
        required: true,
        message: '请输入培训主题',
        trigger: 'blur',
      },
      lecturers: {
        required: true,
        message: '请选择培训讲师',
        trigger: 'change',
      },
    })

    watch(
    () => state.detailform.dateRange,
    (newValue) => {
      console.log('watch detailform.dateRange value is: ', newValue)
      if (newValue) {
        state.detailform.startTime = newValue[0] || ''
        state.detailform.endTime = newValue[1] || ''
      } else {
        state.detailform.startTime = ''
        state.detailform.endTime = ''
      }
    })
    const getData = async () => {
      try {
        const res = await request('getTrainingList', {
          ...state.query,
          page: state.page,
          size: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startDate = data.dateRange[0]
        state.query.endDate = data.dateRange[1]
      } else {
        state.query.startDate = ''
        state.query.endDate = ''
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    }
    // 二维码下载
    const downloadWXAcode = async (row) => {
      try {
        const qrCode = await request("qrCodeTraining", {
          id: row.id
        })
        let a = document.createElement('a')
        a.download = row.subject
        a.href = 'data:image/jpeg;base64,' + qrCode
        a.click()
      } catch (error) {
        console.error(error)
      }
    }
    const checkResult = (row) => {
      if (!row.count) return
      router.push({ path: '/train/questionnaire', query: { qid: row.id } })
    }
    const addNew = () => {
      state.dialogFormVisible = true
      state.detailform = {
        dateRange: [],
        location: '',
        subject: '',
        lecturers: []
      }
    }
    const editDetail = async (row) => {
      state.dialogFormVisible = true
      const dateRange = row.time.split(' -- ')
      console.log(dateRange)
      state.detailform = {
        id: row.id,
        dateRange: dateRange,
        location: row.location,
        subject: row.subject,
        lecturers: row.lecturers.map((item)=>{return item.id})
      }
      state.staffList = row.lecturers
      console.log(row.lecturers)
    }
    const deleteDetail = async (row) => {
      ElMessageBox({
        title: '提示',
        message: '确定删除？',
        type: 'warning',
        showCancelButton: 'true',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        await request("removeTraining", {
          id: row.id
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      }).catch(() => {
      })
    }
    const staffQuery = async (name) => {
      try {
        if (!name) return
        const res = await request('searchEmp', {
          empName: name
        })
        state.staffList = res.slice(0, 30)
      } catch (error) {
        console.error(error)
      }
    }

    const onFormSubmit = async () => {
      try {
        // addTraining:新增 editTraining:编辑
        state.detailformRef && await state.detailformRef.validate()
        let url = state.detailform.id ? 'editTraining' : 'addTraining'
        await request(url, state.detailform)
        getData()
        state.dialogFormVisible = false
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    getData();
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      updatePage,
      downloadWXAcode,
      checkResult,
      addNew,
      editDetail,
      deleteDetail,
      staffQuery,
      onFormSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
</style>