<template>
  <div class="page-container">
    <el-card class="box-card" :body-style="{ flex: 1, overflow: 'auto' }">
      <template #header>
        <div class="card-header">
          <div>机器人小程序码生成</div>
          <div style="margin-left: 20px;">
            <el-button @click="state.mode = 0" :type="state.mode == 0 ? 'primary' : 'default'">单个生成</el-button>
            <el-button @click="state.mode = 1" :type="state.mode == 1 ? 'primary' : 'default'">批量生成</el-button>
          </div>
        </div>
      </template>
      <div v-if="state.mode == 0">
        <el-row>
          <el-col :span="12">
            <el-form inline>
              <el-form-item label="机器人编码：">
                <el-input v-model.trim="state.robotId" clearable placeholder="请输入机器人编码" style="width: 300px;"></el-input>
              </el-form-item>
            <el-button :disabled="!state.robotId" size="small" type="primary" @click="createOne">生成二维码</el-button>
          </el-form>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;align-items: flex-start;">
              <el-image class="image__error" style="width: 300px;margin-right: 10px;" :src="state.imageUrl">
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture"></i>
                  </div>
                </template>
              </el-image>
              <el-button :disabled="!state.imageUrl" @click="downloadImage" size="small" type="primary">立即下载</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="state.mode == 1">
        <el-row>
          <el-col :span="12">
            <div>
              <el-button @click="importFile" type="primary" size="small">导入Excel</el-button>
              <input ref="inputFile" type="file" style="display:none;" @input="uploadExcel">
              <el-button @click="downloadExcel" type="success" size="small" style="margin-left: 20px;">下载Excel模板</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <el-table class="dialog-table" height="500px" :data="state.robotList" border stripe>
              <el-table-column type="index" align="center" label="序号" width="60px" />
              <el-table-column prop="robotId" align="center" label="机器人编码"></el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <template #default="{ $index }">
                  <el-button type="danger" text plain size="small" @click="state.robotList.splice($index, 1)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="margin: 10px 0;">
              <el-button :disabled="!state.robotList?.length" size="small" @click="downloadBatch" type="primary">立即下载</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <canvas ref="canvasRef" style="visibility: hidden;position: fixed;"></canvas>
    </el-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import XLSX from 'xlsx';
import JSzip from 'jszip'

export default {
  components: {
  },
  mixins: [common],
  setup() {
    const state = reactive({
      robotId: '',
      robotList: [],
      imageUrl: '',
      mode: 0, // 0 单个生成 批量生成
    })
    const inputFile = ref(null)
    const canvasRef = ref(null)
    const getWxacode = async (robotId) => {
      return request("getWxacode", {
        path: `pages/detail/detail?robotId=${robotId}`,
        width: 800
      })
    }
    const importFile = function() {
      console.log('data.inputFile', inputFile)
      inputFile.value.click()
    }
    const uploadExcel = (event) => {
      const file = event.target.files[0]
      const fr = new FileReader()
      fr.readAsArrayBuffer(file)
      fr.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const results = XLSX.utils.sheet_to_json(worksheet)
        console.log('XLSX', results)
        state.robotList = results.map(item => ({ robotId: item['机器人编码'] }))
        event.target.value = null
      }
    }
    const downloadExcel = () => {
      let aoa = [
        ['机器人编码'],
        ['Zu10000'],
        ['Zu10001'],
        ['Zu10002'],
        ['Zu10003'],
        ['Zu10004'],
        ['Zu10005'],
      ]
      let sheet = XLSX.utils.aoa_to_sheet(aoa);
      let blob = sheet2blob(sheet)
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        let a = document.createElement('a')
        a.download = '模板.xlsx'
        a.href = e.target.result
        a.click();
      }
    }
    const createWxacode = (imgUrl, robotId) => {
      return new Promise((resolve, reject) => {
        const canvas = canvasRef?.value
        if (canvas) {
          const ctx = canvas.getContext('2d')
          const codeImage = new Image()
          codeImage.src = imgUrl
          codeImage.onload = () => {
            console.log('codeImage', codeImage, codeImage.width, codeImage.height)
            const width = codeImage.width
            const height = codeImage.height
            canvas.width = width
            canvas.height = height
            ctx.drawImage(codeImage, 0, 0)
            ctx.fillStyle = "#FFF";
            ctx.fillRect(0, 800, width, 200)
            ctx.font = "104px sans-serif";
            ctx.textAlign = 'center'
            ctx.fillStyle = "#000";
            ctx.fillText(robotId, canvas.width/2, canvas.width + 60)
            resolve(canvas.toDataURL())
          }
        } else {
          reject(false)
        }
      })
    }
    const createOne = async () => {
      try {
        const res = await getWxacode(state.robotId)
        const srcUrl = 'data:image/jpeg;base64,' + res
        state.imageUrl = await createWxacode(srcUrl, state.robotId)
      } catch (error) {
        console.error(error)
      }
    }
    const downloadImage = () => {
      if (state.imageUrl) {
        let a = document.createElement('a')
        a.download = 'image/png'
        a.href = state.imageUrl
        a.click()
      }
    }
    const downloadBatch = async () => {
      try {
        const zip = new JSzip()
        for(let item of state.robotList) {
          const res = await getWxacode(item.robotId)
          const srcUrl = 'data:image/jpeg;base64,' + res
          const resUrl = await createWxacode(srcUrl, item.robotId)
          const resData = resUrl.split('base64,')[1]
          zip.file(item.robotId + '.png', resData, { base64: true })
        }
        zip.generateAsync({
            type: 'base64'
        }).then(content => {
          let a = document.createElement('a')
          a.download = 'wechatCode.zip'
          a.href = "data:application/zip;base64," + content
          a.click()
        })
      } catch (error) {
        console.error(error)
      }
    }
    function sheet2blob(sheet, sheetName) {
      sheetName = sheetName || 'sheet1';
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
      };
      workbook.Sheets[sheetName] = sheet;
      // 生成excel的配置项
      var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
      };
      var wbout = XLSX.write(workbook, wopts);
      var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
      // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      return blob;
    }
    onMounted(() => {
    })
    return {
      state,
      importFile,
      inputFile,
      canvasRef,
      createOne,
      downloadImage,
      downloadBatch,
      uploadExcel,
      downloadExcel
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.card-header {
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.image__error .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background: #f0f2f5;
  color: #909399;
  font-size: 30px;
  // margin: 20px 0;
}
</style>
