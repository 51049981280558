<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" tooltip-effect="light">
      <el-table-column prop="publishTime" label="发布日期" width="120px" />
      <el-table-column prop="cabinet" label="控制器" width="120px" />
      <el-table-column prop="app" label="APP" width="120px" />
      <el-table-column prop="servo" label="伺服" width="120px" />
      <el-table-column prop="pushAll" label="一键升级" width="120px" />
      <el-table-column label="SCB/PSCB">
        <el-table-column prop="cabV21" label="CAB V2.1" width="120px" />
        <el-table-column prop="miniCab" label="MiniCAB" width="120px" />
      </el-table-column>
      <el-table-column prop="can" label="自研CAN卡" width="120px" />
      <el-table-column prop="webPackage" label="web服务包" width="120px" />
      <el-table-column prop="addOn" label="addon" width="120px" />
      <el-table-column prop="newFunction" label="新增功能" min-width="200">
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.newFunction}}
            </template>
            <template #reference>
              <span style="white-space: nowrap;">{{row.newFunction}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="fixedBugs" label="修复BUG" min-width="200">
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.fixedBugs}}
            </template>
            <template #reference>
              <span style="white-space: nowrap;">{{row.fixedBugs}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="260px">
        <template #default="scope">
          <el-button type="primary" plain size="mini" @click="checkDetail(scope.row)">查看配套软件</el-button>
          <el-button type="primary" plain size="mini" @click="editDetail(scope.row)">编辑</el-button>
          <el-button type="danger" plain size="mini" @click="deleteDetail(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="新增版本" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="180px">
        <el-form-item label="发布日期" prop="publishTime">
          <el-date-picker
            v-model="detailform.publishTime"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="发布日期"
            clearable
          />
        </el-form-item>
        <el-form-item label="控制器" prop="cabinet">
          <el-input v-model="detailform.cabinet" placeholder="控制器" clearable />
        </el-form-item>
        <el-form-item label="APP" prop="app">
          <el-input v-model="detailform.app" placeholder="APP" clearable />
        </el-form-item>
        <el-form-item label="伺服(Zu, C, Pro)" prop="servo">
          <el-input v-model="detailform.servo" placeholder="伺服(Zu, C, Pro)" clearable />
        </el-form-item>
        <el-form-item label="伺服(MC)" prop="servoMc">
          <el-input v-model="detailform.servoMc" placeholder="伺服(MC)" clearable />
        </el-form-item>
        <el-form-item label="一键升级" prop="pushAll">
          <el-input v-model="detailform.pushAll" placeholder="一键升级" clearable />
        </el-form-item>
        <el-form-item label="SCB/PSCB(CAB V2.1)">
          <el-input v-model="detailform.cabV21" placeholder="SCB/PSCB(CAB V2.1)" clearable />
        </el-form-item>
        <el-form-item label="SCB/PSCB(MiniCAB)">
          <el-input v-model="detailform.miniCab" placeholder="SCB/PSCB(MiniCAB)" clearable />
        </el-form-item>
        <el-form-item label="自研CAN卡">
          <el-input v-model="detailform.can" placeholder="自研CAN卡" clearable />
        </el-form-item>
        <el-form-item label="web服务包" prop="webPackage">
          <el-input v-model="detailform.webPackage" placeholder="web服务包" clearable />
        </el-form-item>
        <el-form-item label="addon" prop="addOn">
          <el-input v-model="detailform.addOn" placeholder="addon" clearable />
        </el-form-item>
        <el-form-item label="新增功能">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="新增功能"
            clearable
            v-model="detailform.newFunction"
          />
        </el-form-item>
        <el-form-item label="修复BUG">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="修复BUG"
            clearable
            v-model="detailform.fixedBugs"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit">确认</el-button>
      </template>
    </el-dialog>
    <el-dialog title="配套软件" top="5vh" v-model="dialogTableVisible" destroy-on-close :modal="true" :append-to-body="true" custom-class="dialog-wrapper">
      <el-form class="dialog-form" label-width="180px" :inline="false" label-position="left" v-if="versionInfo">
        <el-form-item class="form-item" label="控制器：" v-if="versionInfo.cabinet && versionInfo.cabinet.length !==0">
          <el-table class="dialog-table" :data="versionInfo.cabinet" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="App：" v-if="versionInfo.app && versionInfo.app.length !==0">
          <el-table class="dialog-table" :data="versionInfo.app" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="伺服(Zu, C, Pro)：" v-if="versionInfo.servo && versionInfo.servo.length !==0">
          <el-table class="dialog-table" :data="versionInfo.servo" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="伺服(MC)：" v-if="versionInfo.servoMc && versionInfo.servoMc.length !==0">
          <el-table class="dialog-table" :data="versionInfo.servoMc" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="SCB/PSCB(CAB V2.1)：" v-if="versionInfo.cab_v21 && versionInfo.cab_v21.length !==0">
          <el-table class="dialog-table" :data="versionInfo.cab_v21" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="SCB/PSCB(MiniCAB)：" v-if="versionInfo.miniCab && versionInfo.miniCab.length !==0">
          <el-table class="dialog-table" :data="versionInfo.miniCab" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="web服务包：" v-if="versionInfo.webPackage && versionInfo.webPackage.length !==0">
          <el-table class="dialog-table" :data="versionInfo.webPackage" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="addon：" v-if="versionInfo.addOn && versionInfo.addOn.length !==0">
          <el-table class="dialog-table" :data="versionInfo.addOn" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="form-item" label="一键升级：" v-if="versionInfo.pushAll && versionInfo.pushAll.length !==0">
          <el-table class="dialog-table" :data="versionInfo.pushAll" border stripe>
            <el-table-column prop="date" label="日期" ></el-table-column>
            <el-table-column prop="platformName" label="平台" ></el-table-column>
            <el-table-column prop="packageName" label="安装包" >
              <template #default="{row}">
                <a :href="row.url" download="software">{{row.packageName}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      detailformRef: null,
      detailform: {
        id: '',
        publishTime: '',
        cabinet: '',
        app: '',
        servo: '',
        servoMc: '',
        pushAll: '',
        cabV21: '',
        miniCab: '',
        can: '',
        newFunction: '',
        fixedBugs: '',
        webPackage: '',
        addOn: '',
      },
      params: [
        {
          key: "cabinet",
          value: "",
          label: "控制器",
        },
        {
          key: "app",
          value: "",
          label: "APP",
        },
        {
          key: "servo",
          value: "",
          label: "伺服",
        }
      ],
      dialogTableVisible: false,
      versionInfo: null
    })
    const detailformRules = reactive({
      publishTime: {
        required: true,
        message: '请选择发布日期',
        trigger: 'change',
      },
      // cabinet: {
      //   required: true,
      //   message: '请输入控制器',
      //   trigger: 'blur',
      // },
      app: {
        required: true,
        message: '请输入APP',
        trigger: 'blur'
      },
      servo: {
        required: true,
        message: '请输入伺服',
        trigger: 'blur',
      }
    })
    const getData = async () => {
      console.log("query", state.query);
      try {
        const res = await request("getVersionMappingList", {
          ...state.query,
          pageNum: state.page,
          pageSize: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data;
      getData()
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };
    const addNew = () => {
      for (let key of Object.keys(state.detailform)) {
        state.detailform[key] = "";
      }
      state.dialogFormVisible = true;
      console.log(state.detailform);
    }
    // 查看配套软件
    const checkDetail = async (row) => {
      try {
        state.dialogTableVisible=true
        state.versionInfo= row.packages || {}
      } catch (error) {
        console.error(error);
      }
    };
    const editDetail = (row) => {
      for (let key of Object.keys(state.detailform)) {
        state.detailform[key] = row[key]??null;
      }
      state.dialogFormVisible = true;
      console.log(state.detailform);
    }
    const deleteDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteVersionMapping", {
          id: row.id
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error)
      }
    } 
    const editSubmit = async () => {
      console.log('onSubmit', state.detailform)
      try {
        state.detailformRef && await state.detailformRef.validate()
        const url = state.detailform.id ? 'updateVersionMappering' : 'saveVersionMapping'
        await request(url, state.detailform)
        state.dialogFormVisible = false
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    getData();
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      updatePage,
      addNew,
      checkDetail,
      editDetail,
      deleteDetail,
      editSubmit,
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>